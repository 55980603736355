const projects = [
    {
        title: 'Scientist Beyond',
        description: 'We need ',
        image: 'test2.jpeg',
        type: 'browsed',
        // linktoabout: 'linktoabout'
    },

    {
        title: 'Find Someone Like You',
        description: 'new project',
        image: 'project2.jpg',
        type: 'browsed',
    },
    {
        title: 'Knife Talk',
        description: 'new project',
        image: 'project2.jpg',
        type: 'browsed',
    },

    {
        title: 'Roll Some Mo',
        description: 'help us',
        image: 'project2.jpg',
        type: 'profile',
    },
    {
        title: 'Karma',
        description: 'help us',
        image: 'test2.jpeg',
        type: 'profile',
    },
    {
        title: 'Energy',
        description: 'help us',
        image: 'test2.jpeg',
        type: 'profile',
    },

    {
        title: 'Brand New',
        description: 'saved',
        image: 'project2.jpg',
        type: 'bookmarked',
    },
    {
        title: 'Peter Pan',
        description: 'hello',
        image: 'project2.jpg',
        type: 'bookmarked',
    },
]

export default projects
